

/*
 * @Author: 李云涛
 * @LastEditors: 李云涛
 * @description: 格式化时间
 * @Date: 2022-04-07 12:02:42
 * @LastEditTime: 2022-04-07 12:04:00
 */
export default function formatTime(val){
  let time = new Date(val * 1000)
  return `
    ${time.getFullYear()}-${time.getMonth()+1 < 10 ? '0' + (time.getMonth()+1) : time.getMonth()+1}-${time.getDate() < 10 ? '0' + time.getDate() : time.getDate()} ${time.getHours() < 10 ? '0' + time.getHours() : time.getHours()}:${time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()}:${time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds()}
  `
}